import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Container, Row, Col } from 'react-bootstrap';
import Gallery from "../../components/Gallery/Gallery";
import ProgressBarLoader from '../../components/ProgressBarLoader/ProgressBarLoader';

const PhotoGallery = () => {
  return (
    <>
      <ProgressBarLoader />
      <Breadcrumbs title="Galeria de Fotos" pagename="Galeria de Fotos" />
        <section className="py-5" style={{overflow:"hidden"}}>
            <Container>
                <Row>
                    <Col>
                        <Gallery />
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}

export default PhotoGallery
