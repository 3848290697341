import React, { useEffect, useState } from "react";
import { Container, Navbar, Offcanvas, Nav, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Logo from '../../../assets/images/logo-viala-blanco.png';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import "../Header/header.css";
import Loading from "../../Loading/Loading";
import Swal from 'sweetalert2';
import { FaSearch, FaTicketAlt, FaInfoCircle } from "react-icons/fa";
import { use } from "react";

const Header = () => {
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState("");
  const [open, setOpen] = useState(false);
  const [showprint, setShowPrint] = useState(false);
  const [company, setCompany] = useState([]);
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const URLZFILES = process.env.REACT_APP_API_ZFILES;
  const URLPUBLIC = process.env.REACT_APP_PUBLIC;

  const toggleMenu = () => {
    setOpen(!open);
  };

  useEffect(() => {
    fetch(URLPUBLIC + 'data/company/company.json')
      .then(response => response.json())
      .then(data => {
        setCompany(data);
      });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky)
    }
  })

  const handleClosePrint = () => setShowPrint(false);
  const handleShowPrint = () => setShowPrint(true);

  // sticky Header 
  const isSticky = (e) => {
    const header = document.querySelector('.header-section');
    const scrollTop = window.scrollY;
    scrollTop >= 120 ? header.classList.add('is-sticky') :
      header.classList.remove('is-sticky')
  }

  const handlePrint = async (e) => {
    e.preventDefault();
    setLoading(true);
    let user_id = 1;
    const form = new FormData();
    form.append("invoice", invoice);
    form.append("user_id", user_id);
    await axios.post(ENDPOINT + 'print-pdf-ticket-invoice', form, { responseType: "blob" }).then((response) => { 
      const url = window.URL.createObjectURL(response.data);
      window.open(url, '_blank');
      setLoading(false);
      setInvoice("");
      handleClosePrint();
    }).catch((error) => {
      setLoading(false);  
      Swal.fire({
        title: 'Error!',  
        text: 'No se encontrado registro en la busqueda, verifique el folio',
        icon: 'error',
      });
    });
  };

  const handleNavLinkClick = () => {
    setOpen(false);
  };

  return (
    <>
      {loading && <Loading />}
      <Modal 
        show={showprint} 
        onHide={handleClosePrint} 
        backdrop="static" 
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="print-modal"
      >
        <Modal.Header closeButton className="bg-header-modal">
          <Modal.Title>
            <FaTicketAlt className="me-2" />
            Reimprimir Boleto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <form id="formulario" onSubmit={handlePrint}>
            <div className="modal-content-wrapper">
              <div className="info-section">
                <FaInfoCircle className="info-icon" />
                <p className="description-text">
                  Para reimprimir su boleto, ingrese el número de folio que recibió en su correo electrónico
                </p>
              </div>
              <div className="input-section">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Ingrese el folio del boleto"
                    id="invoice"
                    name="invoice"
                    value={invoice}
                    onChange={(e) => setInvoice(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="button-section">
                <button type="submit" className="search-btn">
                  <FaSearch className="me-2" />
                  Buscar Boleto
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <header className="header-section">
        <Container className="header-container">
          <Navbar expand="lg" className="p-0">
            {/* Logo Section  */}
            <Navbar.Brand>
              <NavLink to="/">
                <img
                  src={company.logo_main ? URLZFILES + company.logo_main : Logo}
                  width="130"
                  height="80"
                  alt="Logo"
                />
              </NavLink>
            </Navbar.Brand>
            {/* End Logo Section  */}
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="start"
              show={open}
            >
              {/*mobile Logo Section  */}
              <Offcanvas.Header>
                <NavLink to="/">
                  <img
                    src={company.logo_main ? URLZFILES + company.logo_main : Logo}
                    width="150"
                    height="90"
                    alt="Logo"
                  />
                </NavLink>
                <span className="navbar-toggler ms-auto" onClick={toggleMenu}>
                  <i className="bi bi-x-lg"></i>
                </span>
              </Offcanvas.Header>
              {/*end mobile Logo Section  */}
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 pe-3">
                  <NavLink className="nav-link" to="/" onClick={handleNavLinkClick}>
                    Inicio
                  </NavLink>

                  <NavLink className="nav-link" to="nosotros" onClick={handleNavLinkClick}>
                    Nosotros
                  </NavLink>

                  <NavLink className="nav-link" to="destinos" onClick={handleNavLinkClick}>
                    Destinos
                  </NavLink>

                  <NavLink className="nav-link" to="servicios" onClick={handleNavLinkClick}>
                    Servicios
                  </NavLink>

                  <NavLink className="nav-link" to="blog" onClick={handleNavLinkClick}>
                    Blog
                  </NavLink>

                  <NavLink className="nav-link" to="galeria" onClick={handleNavLinkClick}>
                    Galeria
                  </NavLink>

                  <NavLink className="nav-link" to="contacto" onClick={handleNavLinkClick}>
                    Contacto
                  </NavLink>
                </Nav>
                
                {/* Social Media Links - Mobile Only */}
                <div className="social-links d-lg-none text-center py-3">
                  <a href="https://www.facebook.com/TGrupoViala" className="social-icon" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/grupo_viala/" className="social-icon" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-instagram"></i>
                  </a>
                  {/*<a href="#" className="social-icon" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-twitter-x"></i>
                  </a>*/}
                </div>

                {/* Print Button - Mobile Only */}
                <div className="d-lg-none text-center py-3">
                  <NavLink
                    className="print-btn"
                    onClick={() => {
                      handleShowPrint();
                      setOpen(false);
                    }}
                  >
                    <i className="bi bi-printer"></i>
                    <span className="print-text">
                      Reimprimir Boleto
                    </span>
                  </NavLink>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <div className="nav-end-section ms-auto d-flex align-items-center">
              <div className="social-links d-none d-lg-flex me-3">
                <a href="https://www.facebook.com/TGrupoViala" className="social-icon" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="https://www.instagram.com/grupo_viala/" className="social-icon" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-instagram"></i>
                </a>
                {/*<a href="#" className="social-icon" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-twitter-x"></i>
                </a>*/}
              </div>
              
              <NavLink
                className="print-btn d-none d-sm-inline-block"
                onClick={handleShowPrint}
              >
                <i className="bi bi-printer"></i>
                <span className="print-text">
                  Reimprimir Boleto
                </span>
              </NavLink>
              <li className="d-inline-block d-lg-none ms-3 toggle_btn">
                <i className={open ? "bi bi-x-lg" : "bi bi-list"} onClick={toggleMenu}></i>
              </li>
            </div>
          </Navbar>
        </Container>
      </header>
    </>
  );
};

export default Header;
