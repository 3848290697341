import React, { useState, useEffect } from "react";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Form, Container, Row, Col, Card, ListGroup, FloatingLabel } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading/Loading';
import ContactImage from '../../assets/images/contact-us.jpg';
import "../Contact/contact.css";
import ProgressBarLoader from "../../components/ProgressBarLoader/ProgressBarLoader";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('name', name);
    form.append('last_name', last_name);
    form.append('email', email);
    form.append('phone', phone);
    form.append('message', message);
    setLoading(true);
    await axios.post(ENDPOINT + 'send-contact', form).then(response => {
      setLoading(false);
      setName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setMessage('');
      Swal.fire({
        icon: 'success',
        title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
        showConfirmButton: true,
        html: '<div class="text-custom-alert">' + response.data.msg + "</div>",
        showClass: { popup: 'animate__animated animate__fadeInDown' },
        hideClass: { popup: 'animate__animated animate__fadeOutUp' }
      });
    }).catch(function (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
        showConfirmButton: true,
        html: '<div class="text-custom-alert">' + error.response.data.msg + "</div>",
        showClass: { popup: 'animate__animated animate__fadeInDown' },
        hideClass: { popup: 'animate__animated animate__fadeOutUp' }
      })
    });
  };

  return (
    <>
      {loading && <Loading />}
      <ProgressBarLoader />
      <Breadcrumbs title="Contacto" pagename="Contacto" />
      <section className="contact pt-1">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading text-center">
                <h1 className="text-uppercase">CONTÁCTENOS</h1>
                <h6 className="h6 text-uppercase mt-3">CONTACTO PARA CUALQUIER CONSULTA O INQUIETUD</h6>
              </div>
            </Col>
          </Row>

          <Row className="py-4">
            <Col lg="4" md="6" className="mb-4 mb-lg-0">
              <Card className="border shadow rounded-3 mb-4 card-equal-height">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-item-search my-2">
                    <div className="bg-info rounded-circle text-info shadow-sm bg-opacity-10 p-2">
                      <i className="bi bi-headset h3"></i>
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">Llamamos</Card.Title>
                  <p className="mb-3 body-text">
                    Contactanos por medio de los siguientes números de teléfonos.
                  </p>
                  <div className="d-block justify-content-between">
                    <a href="tel:529717021197" type="button" className="btn btn-light me-2 btn-sm">
                      <i className="bi bi-telephone me-1"></i> +52 971 702 1197
                    </a>
                    <a href="tel:529717021197" type="button" className="btn btn-light me-2 btn-sm">
                      <i className="bi bi-telephone me-1"></i> +52 971 702 1197
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg="4" md="6" className="mb-4 mb-lg-0">
              <Card className="border shadow rounded-3 mb-4 card-equal-height">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-item-search my-2">
                    <div className="bg-danger rounded-circle text-danger shadow-sm bg-opacity-10 p-2">
                      <i className="bi bi-envelope h3"></i>
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">Email</Card.Title>
                  <p className="mb-3 body-text">
                    Escribenos un mensajes en el siguiente correo electrónico.
                  </p>
                  <div className="d-block justify-content-between">
                    <a href="mailto:info@grupoviala.com" type="button" className="btn btn-light me-2 btn-sm">
                      <i className="bi bi-envelope me-2"></i> info@grupoviala.com
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg="4" md="12" className="mb-4 mb-lg-0">
              <Card className="border shadow rounded-3 mb-4 card-equal-height">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-item-search my-2">
                    <div className="bg-warning rounded-circle text-warning shadow-sm bg-opacity-10 p-2">
                      <i className="bi bi-globe h3"></i>
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">Redes Sociales</Card.Title>
                  <p className="mb-3 body-text">
                    Siguenos a través de nuestras redes sociales para esta informado.
                  </p>
                  <div className="d-block">
                    <ListGroup horizontal className="justify-content-center">
                      <ListGroup.Item className="border-0">
                        <a href="#" className="social-icon-link">
                          <i className="bi bi-facebook"></i>
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item className="border-0">
                        <a href="#" className="social-icon-link">
                          <i className="bi bi-instagram"></i>
                        </a>
                      </ListGroup.Item>
                      <ListGroup.Item className="border-0">
                        <a href="#" className="social-icon-link">
                          <i className="bi bi-twitter-x"></i>
                        </a>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="py-4 align-items-center">
            <Col xl="6" md="6" className="contact-image-wrapper">
              <img src={ContactImage} alt="Contact" className="img-fluid rounded-3 shadow-sm contact-image" />
            </Col>
            <Col xl="6" md="6">
              <Card className="bg-light p-4 border-0 shadow-sm">
                <div className="form-box">
                  <h1 className="h3 font-bold mb-4">Enviamos un mensaje</h1>
                  <Form onSubmit={handleSubmit} >
                    <Row>
                      <Col md="6">
                        <FloatingLabel
                          controlId="name"
                          label="Nombres"
                          className="mb-4"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Nombres"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            required
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md="6">
                        <FloatingLabel
                          controlId="last_name"
                          label="Apellidos"
                          className="mb-4"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Apellidos"
                            onChange={(e) => setLastName(e.target.value)}
                            value={last_name}
                            required
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md="6">
                        <FloatingLabel
                          controlId="email"
                          label="Email"
                          className="mb-4"
                        >
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md="6">
                        <FloatingLabel
                          controlId="phone"
                          label="Teléfono"
                          className="mb-4"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Teléfono"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            required
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md="12">
                        <FloatingLabel
                          controlId="message"
                          label="Mensaje"
                          className="mb-4"
                        >
                          <Form.Control
                            as="textarea"
                            placeholder="Mensaje"
                            style={{ height: "125px" }}
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                            required
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <button className="primaryBtn mt-3" type="buttpn">Enviar Mensaje</button>
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="map-section">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3814.327933120231!2d-96.71538952488474!3d17.0566047122502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c723616e2ad9a5%3A0xe95386e64bb4f24b!2sTransportes%20turisticos%20Grupo%20VIALA!5e0!3m2!1ses-419!2sco!4v1734319370425!5m2!1ses-419!2sco" 
          width="100%" 
          height="600" 
          style={{border:0}} 
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade">
        </iframe>
      </section>

      
    </>
  )
}

export default Contact;
