import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { NavLink } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import "../Breadcrumbs/breadcrumbs.css";

const Breadcrumbs = (props) => {
    return (
        <div className="breadcrumb-wrapper">
            <div className="banner-section">
                <div className="banner-overlay"></div>
                <Container>
                    <div className="banner-content">
                        <h1>{props.title}</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <NavLink to="/">
                                    <i className="bi bi-house-door"></i>
                                    <span>Inicio</span>
                                </NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <span>{props.pagename}</span>
                            </Breadcrumb.Item>
                            {props.childpagename && (
                                <Breadcrumb.Item active>
                                    <span>{props.childpagename}</span>
                                </Breadcrumb.Item>
                            )}
                        </Breadcrumb>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default Breadcrumbs;
