import React, { useState, useEffect } from "react";
import { Col, Container, Row, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../Footer/footer.css";

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [year, setYear] = useState(0);
  const [company, setCompany] = useState([]);
  const LOGIN = process.env.REACT_APP_LOGIN;
  const URLPUBLIC = process.env.REACT_APP_PUBLIC;

  useEffect(() => {
    const current = new Date();
    const year = current.getFullYear();
    setYear(year);
    fetch(URLPUBLIC + 'data/company/company.json')
      .then(response => response.json())
      .then(data => {
        setCompany(data);
      });
  }, []);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", toggleVisible);
  }

  return (
    <>
      <footer className="pt-5 modern-footer">
        <Container>
          <Row>
            <Col md="3" sm="12" className="quick_link mt-3 mt-md-0">
              <h4 className="mt-lg-0 mt-sm-3">Información</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <a
                    target="_blank"
                    href="https://maps.app.goo.gl/DWg2spCkWAgeJRjY8"
                  >
                    <i className="bi bi-geo-alt me-3"></i>Oaxaca de Juárez,
                    México
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a target="_blank" href="mailto:info@grupoviala.com">
                    <i className="bi bi-envelope me-3"></i>info@grupoviala.com
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a target="_blank" href="tel:529717021197">
                    <i className="bi bi-telephone me-3"></i>+52 971 702 1197
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col md="3" sm="12" className="quick_link mt-3 mt-md-0">
              <h4 className="mt-lg-0 mt-sm-3">Compañia</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <a href={LOGIN}>
                    <i className="bi bi-person-circle me-3"></i>Accesos Personal
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <NavLink to="nosotros">
                    <i className="bi bi-info-circle me-3"></i>Sobre Nosotros
                  </NavLink>
                </ListGroup.Item>
                <ListGroup.Item>
                  <NavLink to="blog">
                    <i className="bi bi-journal-text me-3"></i>Blog y Noticias
                  </NavLink>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md="3" sm="12" className="quick_link mt-3 mt-md-0">
              <h4 className="mt-lg-0 mt-sm-3">Explorar</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <NavLink to="destinos">
                    <i className="bi bi-geo-alt-fill me-3"></i>Destinos Destacados
                  </NavLink>
                </ListGroup.Item>
                <ListGroup.Item>
                  <NavLink to="servicios">
                    <i className="bi bi-briefcase-fill me-3"></i>Nuestros Servicios
                  </NavLink>
                </ListGroup.Item>
                <ListGroup.Item>
                  <NavLink to="galeria">
                    <i className="bi bi-images me-3"></i>Galeria de Fotos
                  </NavLink>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md="3" sm="12" className="quick_link mt-3 mt-md-0">
              <h4 className="mt-lg-0 mt-sm-3">Enlaces Rápidos</h4>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <NavLink to="/terminos-y-condiciones">
                    <i className="bi bi-file-earmark-text me-3"></i>Términos y
                    Condiciones
                  </NavLink>
                </ListGroup.Item>
                <ListGroup.Item>
                  <NavLink to="/politicas-de-privacidad">
                    <i className="bi bi-shield-lock me-3"></i>Políticas de
                    Privacidad
                  </NavLink>
                </ListGroup.Item>
                <ListGroup.Item>
                  <NavLink to="/contacto">
                    <i className="bi bi-envelope-fill me-3"></i>Contactanos
                  </NavLink>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row className="social-links mt-4">
            <Col className="text-center">
              <a href="https://www.facebook.com/TGrupoViala" className="social-icon" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="https://www.instagram.com/grupo_viala/" className="social-icon" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-instagram"></i>
              </a>
              {/*<a href="#" className="social-icon">
                <i className="bi bi-twitter-x"></i>
              </a>*/}
            </Col>
          </Row>
          <Row className="py-2 bdr mt-5 text-center">
            <Col className="col copyright mt-4 mb-4">
              <p className="text-light">
                © {year} {company.name}. Todos los derechos reservados.
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
      <div
        id="back-top"
        onClick={scrollTop}
        className={visible ? "active" : ""}
      >
        <i className="bi bi-arrow-up"></i>
      </div>
    </>
  );
};

export default Footer;
