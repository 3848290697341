import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { Container, Row, Col } from 'react-bootstrap';
import Cards from '../../components/Cards/Cards';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ProgressBarLoader from '../../components/ProgressBarLoader/ProgressBarLoader';

const Destinations = () => {
  const [info_destinations, setInfoDestinations] = useState([]);
  const [loading, setLoading] = useState(true);
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const URLPUBLIC = process.env.REACT_APP_PUBLIC;

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageUpdate();
    fetch(URLPUBLIC + 'data/pagedestinations/destinations.json')
      .then(response => response.json())
      .then(data => {
        setInfoDestinations(data);
        setLoading(false);
      });
  }, []);
  
  const getPageUpdate = async () => {
    await axios.get(ENDPOINT + 'get-home-page');
  };

  return (
    <>
      <ProgressBarLoader />
      <Breadcrumbs title="Destinos" pagename="Destinos" />
      <section className="py-5">
        <Container>
          <Row>
            {loading ? (
              Array(8).fill().map((_, index) => (
                <Col md="3" sm="6" key={index} className="pb-4">
                  <Skeleton height={200} />
                </Col>
              ))
            ) : (
              info_destinations.map((destination, inx) => {
                return (
                  <Col md="3" sm="6" key={inx} className="pb-4">
                    <Cards destination={destination} key={inx} />
                  </Col>
                );
              })
            )}
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Destinations;
