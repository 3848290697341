import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// Removing the direct import and adding CDN link in the head
import './detailBlog.css';

const DetailBlog = () => {
  useEffect(() => {
    // Dynamically add Bootstrap Icons CDN
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.css';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const [readingProgress, setReadingProgress] = useState(0);

  useEffect(() => {
    const calculateReadingProgress = () => {
      const element = document.documentElement;
      const scrollTop = element.scrollTop - element.clientTop;
      const scrollHeight = element.scrollHeight - element.clientHeight;
      const progress = (scrollTop / scrollHeight) * 100;
      setReadingProgress(progress);
    };

    window.addEventListener('scroll', calculateReadingProgress);
    return () => window.removeEventListener('scroll', calculateReadingProgress);
  }, []);

  return (
    <div className="blog-detail">
      {/* Reading Progress Bar */}
      <div 
        className="reading-progress-bar"
        style={{ width: `${readingProgress}%` }}
      />

      {/* Hero Section */}
      <div className="blog-hero">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="category-badge">Technology</div>
              <h1 className="blog-title">The Future of Artificial Intelligence: Transforming Tomorrow's World Today</h1>
              
              <div className="blog-meta">
                <div className="author-info">
                  <img 
                    src="/placeholder.svg?height=50&width=50" 
                    alt="Author" 
                    className="author-image"
                  />
                  <div className="author-details">
                    <h6 className="mb-0">John Doe</h6>
                    <small>Senior Tech Writer</small>
                  </div>
                </div>
                
                <div className="post-info">
                  <span><i className="bi bi-clock"></i> 8 min read</span>
                  <span><i className="bi bi-eye"></i> 2.4k views</span>
                  <span>Published: March 15, 2024</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container">
        <div className="row justify-content-center">
          {/* Social Share Sidebar */}
          <div className="col-lg-1">
            <div className="social-share-sidebar">
              <button className="share-btn">
                <i className="bi bi-heart"></i>
                <span>127</span>
              </button>
              <button className="share-btn">
                <i className="bi bi-bookmark-plus"></i>
              </button>
              <button className="share-btn">
                <i className="bi bi-share"></i>
              </button>
              <div className="share-divider" />
              <button className="share-btn">
                <i className="bi bi-facebook"></i>
              </button>
              <button className="share-btn">
                <i className="bi bi-twitter"></i>
              </button>
              <button className="share-btn">
                <i className="bi bi-linkedin"></i>
              </button>
            </div>
          </div>

          {/* Article Content */}
          <div className="col-lg-7">
            <article className="blog-content">
              <img 
                src="/placeholder.svg?height=500&width=800" 
                alt="Blog Cover" 
                className="featured-image"
              />
              
              <div className="content-body">
                <p className="lead">
                  Artificial Intelligence is rapidly evolving, reshaping industries and transforming how we live and work. 
                  This comprehensive guide explores the latest developments and future implications of AI technology.
                </p>

                <h2>The Evolution of AI Technology</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>

                <blockquote className="highlight-quote">
                  <i className="bi bi-quote quote-icon"></i>
                  "AI is not just transforming technology; it's revolutionizing every aspect of human existence, 
                  from healthcare to education, from business to entertainment."
                </blockquote>

                <h2>Impact on Various Industries</h2>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>

                <div className="info-card">
                  <h4><i className="bi bi-lightbulb"></i> Key Takeaways</h4>
                  <ul>
                    <li>AI is revolutionizing healthcare diagnosis</li>
                    <li>Machine learning is improving business efficiency</li>
                    <li>Natural language processing is advancing rapidly</li>
                    <li>Ethical considerations in AI development</li>
                  </ul>
                </div>
              </div>

              {/* Tags */}
              <div className="blog-tags">
                <span className="tag"><i className="bi bi-hash"></i>ArtificialIntelligence</span>
                <span className="tag"><i className="bi bi-hash"></i>Technology</span>
                <span className="tag"><i className="bi bi-hash"></i>Future</span>
                <span className="tag"><i className="bi bi-hash"></i>Innovation</span>
              </div>
            </article>

            {/* Author Bio */}
            <div className="author-bio">
              <img 
                src="/placeholder.svg?height=120&width=120" 
                alt="Author" 
                className="author-bio-image"
              />
              <div className="author-bio-content">
                <h4>John Doe</h4>
                <p className="author-title">Senior Tech Writer & AI Researcher</p>
                <p>
                  John is a seasoned technology writer with over a decade of experience covering AI, 
                  machine learning, and emerging tech trends. He holds a Ph.D. in Computer Science 
                  and regularly contributes to leading tech publications.
                </p>
                <button className="btn btn-outline-primary">
                  <i className="bi bi-person-plus me-2"></i>Follow
                </button>
              </div>
            </div>
          </div>

          {/* Related Posts Sidebar */}
          <div className="col-lg-3">
            <div className="related-posts">
              <h5><i className="bi bi-collection me-2"></i>Related Articles</h5>
              
              <div className="related-post-card">
                <img 
                  src="/placeholder.svg?height=150&width=250" 
                  alt="Related Post" 
                />
                <div className="related-post-content">
                  <span className="category-badge-sm">Technology</span>
                  <h6>Machine Learning: A Beginner's Guide</h6>
                  <small><i className="bi bi-clock me-1"></i>5 min read</small>
                </div>
              </div>

              <div className="related-post-card">
                <img 
                  src="/placeholder.svg?height=150&width=250" 
                  alt="Related Post" 
                />
                <div className="related-post-content">
                  <span className="category-badge-sm">Innovation</span>
                  <h6>The Rise of Neural Networks</h6>
                  <small><i className="bi bi-clock me-1"></i>7 min read</small>
                </div>
              </div>

              <div className="related-post-card">
                <img 
                  src="/placeholder.svg?height=150&width=250" 
                  alt="Related Post" 
                />
                <div className="related-post-content">
                  <span className="category-badge-sm">Future</span>
                  <h6>Ethics in Artificial Intelligence</h6>
                  <small><i className="bi bi-clock me-1"></i>6 min read</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailBlog;

