import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "../../components/Loading/Loading";
import "../Booking/booking.css";
import ProgressBarLoader from "../../components/ProgressBarLoader/ProgressBarLoader";
import { FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaDollarSign, FaLuggageCart, FaDog, FaCreditCard, FaCouch } from "react-icons/fa"; // Cambiado FaChair por FaCouch

const Booking = () => {
  const [card, setCard] = useState({
    name: "",
    last_name: "",
    email: "",
    phone_number: "",
    amount: 0,
    description: "Venta de Boleto",
  });
  const [loading, setLoading] = useState(false);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [purchase_order, setPurchaseOrder] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;

  useEffect(() => {
    window.scrollTo(0, 0);
    const dataResume = JSON.parse(localStorage.getItem("dataResume"));
    const dataArray = Object.values(dataResume.dataForm);
    setOrigin(dataResume.origin);
    setDestination(dataResume.destination);
    setData(dataArray);
    setTotal(dataResume.total);
    setPurchaseOrder(dataResume.purchase_order);
    setCard({ ...card, amount: dataResume.total });
    setDate(dataResume.date);
    setTime(dataResume.time);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("amount", total);
    form.append("name", data[0].name);
    form.append("last_name", data[0].last_name);
    form.append("email", data[0].email);
    form.append("phone_number", data[0].phone_number);
    form.append("description", "Venta de Boleto");
    form.append("purchase_order", purchase_order);
    setLoading(true);
    await axios
      .post(ENDPOINT + "payment", form)
      .then((response) => {
        localStorage.removeItem("dataResume");
        localStorage.removeItem("dataTrip");
        window.location.href = response.data.data;
      })
      .catch(function (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: '<div className="title-custom-alert">' + TITLEALERT + "</div>",
          showConfirmButton: true,
          html:
            '<div className="text-custom-alert">' +
            error.response.data.msg +
            "</div>",
          showClass: { popup: "animate__animated animate__fadeInDown" },
          hideClass: { popup: "animate__animated animate__fadeOutUp" },
        });
      });
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formatHour = hour % 12 || 12;
    return `${formatHour}:${minutes} ${ampm}`;
  };


  return (
    <>
      {loading && <Loading />}
      <ProgressBarLoader />
      <Breadcrumbs title="Realizar pago" pagename="Realizar pago" />
      <section className="booking-section py-5">
        <Container>
          <div className="alert alert-warning d-flex align-items-center mb-4 shadow-sm border-warning" 
               style={{
                 backgroundColor: '#fff3cd',
                 borderLeft: '5px solid #ffc107',
                 padding: '1rem 1.5rem'
               }}>
            <div className="d-flex align-items-center gap-3">
              <div className="alert-icon">
                <i className="bi bi-clock-history fs-3 text-warning"></i>
              </div>
              <div className="alert-content">
                <h4 className="alert-heading mb-1 d-flex align-items-center gap-2">
                  <i className="bi bi-exclamation-triangle-fill text-warning"></i>
                  Tiempo límite de pago
                </h4>
                <p className="mb-0">
                  Por favor, complete su pago dentro de los próximos <strong>25 minutos</strong>. 
                  Después de este tiempo, su reserva será cancelada automáticamente y los asientos volverán a estar disponibles.
                </p>
              </div>
            </div>
          </div>
          
          <Row>
            <Col md="8" lg="8">
              <Card className="card-info">
                <Card.Header className="d-flex align-items-center">
                  <FaUser className="header-icon me-2" />
                  <h1 className="h4 m-0">Resumen pasajeros</h1>
                </Card.Header>
                <Card.Body>
                  {data.map((item, index) => (
                    <div key={index} className="passenger-info">
                      <h2 className="h5 passenger-title">
                        <FaUser className="me-2" />Pasajero #{index + 1}
                      </h2>
                      <div className="passenger-data">
                        <span className="passenger-label"><FaUser className="me-2" />Nombres:</span>
                        <span>{item.name}</span>
                      </div>
                      <div className="passenger-data">
                        <span className="passenger-label"><FaUser className="me-2" />Apellidos:</span>
                        <span>{item.last_name}</span>
                      </div>
                      <div className="passenger-data">
                        <span className="passenger-label"><FaEnvelope className="me-2" />Email:</span>
                        <span>{item.email}</span>
                      </div>
                      <div className="passenger-data">
                        <span className="passenger-label"><FaPhone className="me-2" />Teléfono:</span>
                        <span>{item.phone}</span>
                      </div>
                    </div>
                  ))}
                </Card.Body>
              </Card>
            </Col>

            <Col md="4" lg="4">
              <Card className="card-info">
                <Card.Header className="d-flex align-items-center">
                  <FaDollarSign className="header-icon me-2" />
                  <h1 className="h4 m-0">Resumen de Precios</h1>
                </Card.Header>
                <Card.Body>
                  <div className="price-summary mb-4">
                    <div className="d-flex justify-content-between mb-3">
                      <strong><i className="bi bi-geo-fill me-2"></i>Origen</strong>
                      <span>{origin}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <strong><FaMapMarkerAlt className="me-2" />Destino</strong>
                      <span>{destination}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <strong><i className="bi bi-calendar3 me-2"></i>Fecha</strong>
                      <span>{date}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <strong><i className="bi bi-clock me-2"></i>Hora</strong>
                      <span>{formatTime(time)}</span>
                    </div>
                  </div>
                  <div className="price-details">
                    {data.map((item, index) => (
                      <div key={index} className="price-item mb-3">
                        <div className="d-flex justify-content-between">
                          <span><FaCouch className="me-2" />Asiento # {item.seat}</span>
                          <strong>${item.price}</strong>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span><FaLuggageCart className="me-2" />Equipaje Extra</span>
                          <strong>${item.extraLuggage === 1 ? item.extraLuggageAmount : 0}</strong>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span><FaDog className="me-2" />Mascota</span>
                          <strong>${item.pet === 1 ? item.petAmount : 0}</strong>
                        </div>
                      </div>
                    ))}
                  </div>
                </Card.Body>
                <Card.Footer className="d-flex flex-column align-items-center py-4">
                  <div className="d-flex justify-content-between w-100 mb-4">
                    <span className="h5 mb-0 d-flex align-items-center">
                      <FaDollarSign className="me-2" />
                      Total a Pagar
                    </span>
                    <strong className="total-price">${total}</strong>
                  </div>
                  <form onSubmit={handleSubmit} className="w-100 text-center">
                    <button type="submit" className="primaryBtn w-100">
                      <FaCreditCard className="me-2" />
                      Proceder al pago
                    </button>
                  </form>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Booking;
