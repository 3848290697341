import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import "./privacyPolicy.css";
import ProgressBarLoader from "../../components/ProgressBarLoader/ProgressBarLoader";

const PrivacyPolicy = () => {
  return (
    <div>
      <ProgressBarLoader />
      <Breadcrumbs title="Política de Privacidad" pagename="Política de Privacidad" />
      <div className="container py-5">
        <h1 className="text-center mb-5 page-title">Política de Privacidad</h1>
        <div className="privacy-content">
          <h3 className="section-title">1. Introducción</h3>
          <p>
            En Grupo Viala, valoramos su privacidad y nos comprometemos a
            proteger su información personal. Esta política de privacidad
            describe cómo recopilamos, utilizamos y protegemos su información
            cuando utiliza nuestros servicios de venta de boletos de bus.
          </p>

          <h3 className="section-title">2. Información que Recopilamos</h3>
          <p>
            Podemos recopilar información personal que usted nos proporciona
            directamente, como su nombre, dirección de correo electrónico,
            número de teléfono y detalles de pago. También podemos recopilar
            información automáticamente cuando utiliza nuestros servicios, como
            su dirección IP, tipo de navegador y páginas visitadas.
          </p>

          <h3 className="section-title">3. Uso de la Información</h3>
          <p>
            Utilizamos su información personal para procesar sus compras,
            proporcionar servicio al cliente, mejorar nuestros servicios y
            comunicarnos con usted sobre promociones y actualizaciones. No
            compartimos su información personal con terceros, excepto cuando sea
            necesario para procesar su compra o cumplir con requisitos legales.
          </p>

          <h3 className="section-title">4. Seguridad de la Información</h3>
          <p>
            Implementamos medidas de seguridad adecuadas para proteger su
            información personal contra el acceso no autorizado, la alteración,
            divulgación o destrucción. Sin embargo, no podemos garantizar la
            seguridad absoluta de su información.
          </p>

          <h3 className="section-title">5. Sus Derechos</h3>
          <p>
            Usted tiene derecho a acceder, corregir o eliminar su información
            personal en cualquier momento. También puede optar por no recibir
            comunicaciones promocionales de nuestra parte. Para ejercer estos
            derechos, por favor contáctenos a través de nuestro sitio web.
          </p>

          <h3 className="section-title">6. Cambios a esta Política</h3>
          <p>
            Nos reservamos el derecho de modificar esta política de privacidad
            en cualquier momento. Las modificaciones entrarán en vigor
            inmediatamente después de su publicación en nuestro sitio web. Le
            recomendamos revisar esta política periódicamente para estar
            informado sobre cómo protegemos su información.
          </p>

          <h3 className="section-title">7. Contacto</h3>
          <p>
            Si tiene alguna pregunta sobre esta política de privacidad, por
            favor contáctenos a través de nuestro sitio web o en nuestras
            oficinas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
