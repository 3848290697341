import React from 'react';
import Logo from '../../assets/images/logoviala.png';
import "./loading.css";

const Loading = () => {
    return (
        <div id="loading-overlay">
            <div className="loading-content">
                <img id="loading-logo" src={Logo} alt="Logo" />
                <br />
                <h2 className="loading-title ">
                    <i className="bi bi-bus-front me-3"></i> 
                    GRUPO VIALA 
                    <i className="bi bi-bus-front mx-3"></i>
                </h2>
                <p className="loading-text">Cargando, por favor espere...</p>
            </div>
        </div>
    );
}

export default Loading;