import React, { useState, useEffect } from "react";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import Loading from '../../components/Loading/Loading';
import { NavLink } from "react-router-dom";
import "./paymentSuccess.css";
import ProgressBarLoader from '../../components/ProgressBarLoader/ProgressBarLoader';

const PaymentSuccess = () => {
    const [loading, setLoading] = useState(false);
    const [transaction_id, setTransactionId] = useState('');
    const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;

    useEffect(() => {
        window.scrollTo(0, 0);
        const params = new URLSearchParams(window.location.search);
        const transaction_id = params.get('id');
        setTransactionId(transaction_id);
    }, []);

    const paymentStatus = async (transaction_id) => {
        const form = new FormData();
        form.append('transaction_id', transaction_id);
        setLoading(true);
        const response = await axios.post(ENDPOINT + 'status-payment', form, { responseType: "blob" });
        const url = window.URL.createObjectURL(response.data);
        window.open(url, '_blank');
        setLoading(false);
    };

    return (
        <>
            {loading && <Loading />}
            <ProgressBarLoader />
            <Breadcrumbs title="Pago exitoso" pagename="Pago exitoso" />
            <section className="payment-success">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8" lg="6">
                            <Card className="success-card">
                                <div className="success-icon">
                                    <i className="bi bi-check-lg"></i>
                                </div>
                                <div className="success-content">
                                    <h1 className="success-title">
                                        ¡Genial! Tu compra está confirmada
                                    </h1>
                                    <div className="success-message">
                                        <p className="primary-message">
                                            Hemos procesado tu pago exitosamente
                                        </p>
                                        <div className="message-details">
                                            <p>✓ Tu boleto de viaje ha sido enviado a tu correo electrónico</p>
                                            <p>✓ Recibirás una confirmación detallada en los próximos minutos</p>
                                            <p>✓ Puedes descargar tu boleto directamente desde aquí</p>
                                        </div>
                                    </div>
                                    <div className="action-buttons">
                                        <NavLink to="/" className="success-btn home-btn">
                                            <i className="bi bi-house-door me-2"></i>
                                            Volver al inicio
                                        </NavLink>
                                        <button 
                                            onClick={() => paymentStatus(transaction_id)}
                                            className="success-btn download-btn">
                                            <i className="bi bi-download me-2"></i>
                                            Descargar Boleto
                                        </button>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PaymentSuccess;