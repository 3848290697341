import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../Services/services.css';
import ImageNotFound from "../../assets/images/not-found.png";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ProgressBarLoader from '../../components/ProgressBarLoader/ProgressBarLoader';

const Services = () => {
    const [info_services, setInfoServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const URLPUBLIC = process.env.REACT_APP_PUBLIC;
    const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
    const URLZFILES = process.env.REACT_APP_API_ZFILES;

    useEffect(() => {
        window.scrollTo(0, 0);
        getPageUpdate();
        fetch(URLPUBLIC + 'data/pageservices/services.json')
            .then(response => response.json())
            .then(data => {
                setInfoServices(data);
                setLoading(false);
            });
    }, []);

    const getPageUpdate = async () => {
        await axios.get(ENDPOINT + 'get-home-page');
    };

    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    autoplay: true,
                    prevArrow: false,
                    nextArrow: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    prevArrow: false,
                    nextArrow: false,
                },
            },
        ],
    };

    return (
        <>
            <ProgressBarLoader />
            <Breadcrumbs title="Servicios" pagename="Servicios" />
            <section className="feature-section">
                <Container>
                    <Row>
                        <Col md="12">
                            {loading ? (
                                <Slider {...settings}>
                                    {Array(4).fill().map((_, index) => (
                                        <Card key={index}>
                                            <Skeleton height={200} />
                                            <Card.Body>
                                                <Skeleton count={2} />
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </Slider>
                            ) : (
                                <Slider {...settings}>
                                    {info_services.map((item, inx) => {
                                        return (
                                            <Card key={inx}>
                                                <Card.Img
                                                    loading="lazy" 
                                                    variant="top"
                                                    src={item.service_icon ? URLZFILES + item.service_icon : ImageNotFound}
                                                    className="img-fluid"
                                                    alt={item.service_title}
                                                />
                                                <Card.Title>{item.service_title}</Card.Title>
                                                <Card.Text>
                                                    <div dangerouslySetInnerHTML={{ __html: item.service_sub_title }} />
                                                </Card.Text>
                                            </Card>
                                        );
                                    })}
                                </Slider>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Services;