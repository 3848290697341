import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import "../Cards/card.css";
import ImageNotFound from "../../assets/images/not-found.png";

const PopularCard = ({ val }) => {
  const URLZFILES = process.env.REACT_APP_API_ZFILES;

  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  const date = val.date_created.split(' ')[0];

  return (
    <div className="blog-card-wrapper">
      <Card className="rounded-3 shadow-lg blog-card">
        <div className="blog-image-wrapper">
          <Card.Img
            loading="lazy" 
            variant="top"
            src={val.blog_image ? URLZFILES + val.blog_image : ImageNotFound}
            className="img-fluid blog-image"
            alt={val.blog_title}
          />
        </div>
        <Card.Body className="blog-content">
          <div className="location-tag">
            <i className="bi bi-geo-alt-fill"></i>
            <span className="location-text">Oaxaca de Juárez, México</span>
          </div>
          <Card.Title className="blog-title">
            <NavLink className="title-link" to={`/detalle-blog/${val.page_blog_id}`}>
              {val.blog_title}
            </NavLink>
          </Card.Title>
          <span className="badge blog-badge">
            Blog Nacional
          </span>
        </Card.Body>
        <Card.Footer className="blog-footer">
          <div className="date-info">
            <i className="bi bi-calendar3"></i>
            <span>{date.split('-')[2]} {months[date.split('-')[1]-1]} {date.split('-')[0]}</span>
          </div>
        </Card.Footer>
      </Card>
    </div>
  )
}

export default PopularCard;