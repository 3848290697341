import React, { useEffect, useState } from 'react';

const ProgressBAr = () => {
    const [readingProgress, setReadingProgress] = useState(0);

    useEffect(() => {
        const calculateReadingProgress = () => {
            const element = document.documentElement;
            const scrollTop = element.scrollTop - element.clientTop;
            const scrollHeight = element.scrollHeight - element.clientHeight;
            const progress = (scrollTop / scrollHeight) * 100;
            setReadingProgress(progress);
        };
        window.addEventListener('scroll', calculateReadingProgress);
        return () => window.removeEventListener('scroll', calculateReadingProgress);
    }, [])

    return (
        <>
            <div className="reading-progress-bar"  style={{ width: `${readingProgress}%` }} />
        </>
    )
}

export default ProgressBAr


