import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Card, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "../About/about.css";
import ImageNotFound from "../../assets/images/not-found.png";
import ProgressBarLoader from '../../components/ProgressBarLoader/ProgressBarLoader';

const About = () => {
    const [info_about, setInfoAbout] = useState(null);
    const [info_counts, setCounts] = useState(null);
    const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
    const URLZFILES = process.env.REACT_APP_API_ZFILES;
    const URLPUBLIC = process.env.REACT_APP_PUBLIC;

    useEffect(() => {
        window.scrollTo(0, 0);
        getPageUpdate();
        fetch(URLPUBLIC + 'data/pageabout/abouts.json').then(response => response.json()).then(data => setInfoAbout(data));
        fetch(URLPUBLIC + 'data/pagehome/counts.json').then(response => response.json()).then(data => setCounts(data));
    }, []);

    const getPageUpdate = async () => {
        await axios.get(ENDPOINT + 'get-home-page');
    };

    return (
        <>
            <ProgressBarLoader />
            <Breadcrumbs title="Nosotros" pagename="Nosotros" />
            <section className="py-5">
                <Container>
                    <Row>
                        <Col md="8">
                            <div className="about-content">
                                <div className="about-image-wrapper">
                                    {info_about ? (
                                        <img 
                                            loading="lazy" 
                                            src={info_about.about_image ? URLZFILES + info_about.about_image : ImageNotFound} 
                                            alt="Nosotros" 
                                            className="img-fluid" 
                                        />
                                    ) : (
                                        <Skeleton height={400} />
                                    )}
                                    <div className="about-overlay">
                                        <h3 className="h2 mb-0">{info_about ? info_about.about_sub_title : <Skeleton width={200} />}</h3>
                                    </div>
                                </div>
                                <div className="about-text-content">
                                    <h2>{info_about ? info_about.about_title : <Skeleton width={300} />}</h2>
                                    <div className="about-description">
                                        {info_about ? (
                                            <div dangerouslySetInnerHTML={{ __html: info_about.about_description }} />
                                        ) : (
                                            <Skeleton count={5} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md="4">
                            {info_about && info_counts ? (
                                <>
                                    <Card className="border shadow rounded-3 mb-4">
                                        <Card.Body className="text-center">
                                            <div className="d-flex justify-content-center align-item-search my-2">
                                                <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                                                    <img loading="lazy" height={64} width={64} src={info_about.about_indicator_icon_trip ? URLZFILES + info_about.about_indicator_icon_trip : ImageNotFound} alt="icon" className="img-fluid" />
                                                </div>
                                            </div>
                                            <Card.Title className="fw-bold h5">+{info_counts.tickets}</Card.Title>
                                            <p className="mb-2 body-text">
                                                {info_about.about_indicator_text_trip}
                                            </p>
                                        </Card.Body>
                                    </Card>

                                    <Card className="border shadow rounded-3 mb-4">
                                        <Card.Body className="text-center">
                                            <div className="d-flex justify-content-center align-item-search my-2">
                                                <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                                                    <img loading="lazy" height={64} width={64} src={info_about.about_indicator_icon_package ? URLZFILES + info_about.about_indicator_icon_package : ImageNotFound} alt="icon" className="img-fluid" />
                                                </div>
                                            </div>
                                            <Card.Title className="fw-bold h5">+{info_counts.packages}</Card.Title>
                                            <p className="mb-2 body-text">
                                                {info_about.about_indicator_text_package}
                                            </p>
                                        </Card.Body>
                                    </Card>

                                    <Card className="border shadow rounded-3 mb-4">
                                        <Card.Body className="text-center">
                                            <div className="d-flex justify-content-center align-item-search my-2">
                                                <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                                                    <img loading="lazy" height={64} width={64} src={info_about.about_indicator_icon_unit ? URLZFILES + info_about.about_indicator_icon_unit : ImageNotFound} alt="icon" className="img-fluid" />
                                                </div>
                                            </div>
                                            <Card.Title className="fw-bold h5">+{info_counts.units}</Card.Title>
                                            <p className="mb-2 body-text">
                                                {info_about.about_indicator_text_unit}
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </>
                            ) : (
                                [1, 2, 3].map((_, index) => (
                                    <Card className="border shadow rounded-3 mb-4" key={index}>
                                        <Card.Body className="text-center">
                                            <div className="d-flex justify-content-center align-item-search my-2">
                                                <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                                                    <Skeleton circle={true} height={64} width={64} />
                                                </div>
                                            </div>
                                            <Card.Title className="fw-bold h5">
                                                <Skeleton width={50} />
                                            </Card.Title>
                                            <p className="mb-2 body-text">
                                                <Skeleton width={100} />
                                            </p>
                                        </Card.Body>
                                    </Card>
                                ))
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default About;
