import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ProgressBarLoader from "../../components/ProgressBarLoader/ProgressBarLoader";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./validationPackage.css";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ValidationPackage = () => {
  const navigate = useNavigate();
  const { invoice } = useParams();
  const [validationStatus, setValidationStatus] = useState("received"); // received, transit, destination, delivered, cancelled
  const [packageInfo, setPackageInfo] = useState([]);
  const [link_trip, setLinkTrip] = useState("");
  const [loading, setLoading] = useState(true);
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;

  const validateInvoice = async () => {
    const form = new FormData();
    form.append("invoice", invoice);
    setLoading(true);
    await axios
      .post(ENDPOINT + "validation-package-qrcode", form)
      .then((response) => {
        setLoading(false);
        setPackageInfo(response.data.data);
        setLinkTrip(response.data.data.link_trip);
        const status = response.data.data.status;
        if (status == 1) {
          setValidationStatus("received");
        } else if (status == 2) {
          setValidationStatus("transit");
        } else if (status == 3) {
          setValidationStatus("destination");
        } else if (status == 4) {
          setValidationStatus("delivered");
        } else if (status == 5) {
          setValidationStatus("cancelled");
        }
      })
      .catch(function (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: '<div className="title-custom-alert">' + TITLEALERT + "</div>",
          showConfirmButton: true,
          timer: 3000,
          html:
            '<div className="text-custom-alert">' +
            error.response.data.msg +
            "</div>",
          showClass: { popup: "animate__animated animate__fadeInDown" },
          hideClass: { popup: "animate__animated animate__fadeOutUp" },
        });
      });
  };

  useEffect(() => {
    validateInvoice();
  }, [invoice]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("es-ES", options);
  };

  const formatTime = (timeString) => {
    if (!timeString) return "";
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return date.toLocaleTimeString("es-ES", options);
  };

  const seeLocation = () => {
    if (link_trip === "NA") {
      Swal.fire({
        icon: "error",
        title: '<div className="title-custom-alert">' + TITLEALERT + "</div>",
        showConfirmButton: true,
        html: '<div className="text-custom-alert">La ubicación de la unidad no ha sido asignada.</div>',
        showClass: { popup: "animate__animated animate__fadeInDown" },
        hideClass: { popup: "animate__animated animate__fadeOutUp" },
      });
    } else {
      window.open(link_trip, "_blank");
    }
  };

  const passengerDetails = (
    <div className="passenger-details enhanced">
      <div className="detail-item">
        <strong>No. Folio:</strong>
        <span>{invoice}</span>
      </div>
      <div className="detail-item">
        <strong>Remitente:</strong>
        <span>{packageInfo.sender}</span>
      </div>
      <div className="detail-item">
        <strong>Destinatario:</strong>
        <span>{packageInfo.addressee}</span>
      </div>
      <div className="detail-item">
        <strong>Email:</strong>
        <span>{packageInfo.email}</span>
      </div>
      <div className="detail-item">
        <strong>Teléfono:</strong>
        <span>{packageInfo.phone}</span>
      </div>
      <div className="detail-item">
        <strong>Fecha de envio:</strong>
        <span>{formatDate(packageInfo.date_send)}</span>
      </div>
      <div className="detail-item">
        <strong>Horario:</strong>
        <span>{formatTime(packageInfo.time_send)}</span>
      </div>
      <div className="detail-item">
        <strong>Estado del paquete:</strong>
        <span>
          {packageInfo.status === 1
            ? "Recibido"
            : packageInfo.status === 2
            ? "En tránsito"
            : packageInfo.status === 3
            ? "En destino"
            : packageInfo.status === 4
            ? "Entregado"
            : packageInfo.status === 5
            ? "Cancelado"
            : ""}
        </span>
      </div>
      <div className="detail-item">
        <strong>Costo de envio:</strong>
        <span>{packageInfo.price}</span>
      </div>
      <div className="detail-item">
        <strong>Declarado:</strong>
        <span>{packageInfo.declared}</span>
      </div>
      <div className="detail-item">
        <strong>Descripción:</strong>
        <span>{packageInfo.description_package}</span>
      </div>
      <div className="detail-item">
        <strong>Estado de pago:</strong>
        <span>{packageInfo.payment_status === 1 ? "Por pagar" : "Pagado"}</span>
      </div>
      <div className={`detail-item ${packageInfo.payment_status === 2 ? "d-none" : ""}`}>
        <strong>Pendiente de pago:</strong>
        <span>{packageInfo.price - packageInfo.advance}</span>
      </div>
    </div>
  );

  const renderValidationContent = () => {
    if (loading) {
      return (
        <Card className="validation-card loading">
          <Skeleton height={40} width={200} />
          <Skeleton height={20} count={3} />
          <Skeleton height={30} width={150} />
        </Card>
      );
    }

    switch (validationStatus) {
      case "received":
        return (
          <Card className="validation-card">
            <div className="validation-icon info">
              <i className="bi bi-box-seam"></i>
            </div>
            <div className="validation-content">
              <h1 className="validation-title info">Paquete Recibido</h1>
              <div className="validation-message">
                <p className="primary-message info">
                  El paquete ha sido recibido en nuestras instalaciones
                </p>
                <div className="message-details info">
                  <p>✓ Paquete registrado satisfactoriamente</p>
                  <p>✓ Documentación verificada</p>
                  <p>✓ Listo para ser procesado</p>
                </div>
                {passengerDetails}
              </div>
              <div className="action-buttons">
                <button onClick={() => navigate("/")} className="validation-btn info">
                  <i className="bi bi-house-door me-2"></i>
                  Volver al inicio
                </button>
              </div>
            </div>
          </Card>
        );
      case "transit":
        return (
          <Card className="validation-card">
            <div className="validation-icon primary">
              <i className="bi bi-truck"></i>
            </div>
            <div className="validation-content">
              <h1 className="validation-title primary">Paquete en Tránsito</h1>
              <div className="validation-message">
                <p className="primary-message primary">
                  Tu paquete está en ruta hacia su destino
                </p>
                <div className="message-details primary">
                  <p>✓ En tránsito hacia destino final</p>
                  <p>✓ Seguimiento GPS activado</p>
                  <p>✓ Tiempo estimado en curso</p>
                </div>
                {passengerDetails}
              </div>
              <div className="action-buttons">
                <button onClick={() => navigate("/")} className="validation-btn primary">
                  <i className="bi bi-house-door me-2"></i>
                  Volver al inicio
                </button>
                <button onClick={() => seeLocation()} className="validation-btn location">
                  <i className="bi bi-geo-alt me-2"></i>
                  Ver Ubicación
                </button>
              </div>
            </div>
          </Card>
        );
      case "destination":
        return (
          <Card className="validation-card warning">
            <div className="validation-icon warning">
              <i className="bi bi-exclamation-circle"></i>
            </div>
            <div className="validation-content">
              <h1 className="validation-title warning">Paquete en Destino</h1>
              <div className="validation-message">
                <p className="primary-message warning">
                  El paquete ha llegado a la sucursal de destino
                </p>
                <div className="message-details warning">
                  <p>✓ Arribó a sucursal destino</p>
                  <p>✓ Listo para ser retirado</p>
                  <p>✓ Pendiente de entrega al destinatario</p>
                </div>
                {passengerDetails}
              </div>
              <div className="action-buttons">
                <button
                  onClick={() => navigate("/")}
                  className="validation-btn warning"
                >
                  <i className="bi bi-house-door me-2"></i>
                  Volver al inicio
                </button>
              </div>
            </div>
          </Card>
        );
      case "delivered":
        return (
          <Card className="validation-card success">
            <div className="validation-icon success">
              <i className="bi bi-check-circle"></i>
            </div>
            <div className="validation-content">
              <h1 className="validation-title success">Paquete Entregado</h1>
              <div className="validation-message">
                <p className="primary-message success">
                  El paquete ha sido entregado exitosamente
                </p>
                <div className="message-details success">
                  <p>✓ Entregado al destinatario</p>
                  <p>✓ Firma de recibido confirmada</p>
                  <p>✓ Servicio completado</p>
                </div>
                {passengerDetails}
              </div>
              <div className="action-buttons">
                <button
                  onClick={() => navigate("/")}
                  className="validation-btn success"
                >
                  <i className="bi bi-house-door me-2"></i>
                  Volver al inicio
                </button>
              </div>
            </div>
          </Card>
        );

      case "cancelled":
      default:
        return (
          <Card className="validation-card danger">
            <div className="validation-icon danger">
              <i className="bi bi-x-circle"></i>
            </div>
            <div className="validation-content">
              <h1 className="validation-title danger">Paquete Cancelado</h1>
              <div className="validation-message">
                <p className="primary-message danger">
                  El envío ha sido cancelado
                </p>
                <div className="message-details danger">
                  <p>✗ Envío cancelado</p>
                  <p>✗ Proceso interrumpido</p>
                  <p>✗ Contacte a servicio al cliente</p>
                </div>
              </div>
              <div className="action-buttons">
                <button
                  onClick={() => navigate("/")}
                  className="validation-btn danger"
                >
                  <i className="bi bi-house-door me-2"></i>
                  Volver al inicio
                </button>
              </div>
            </div>
          </Card>
        );
    }
  };

  return (
    <>
      <ProgressBarLoader />
      {loading && <Loading />}
      <Breadcrumbs
        title="Validación del Paquete"
        pagename="Validación del Paquete"
      />
      <section className="validation-section">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6">
              {renderValidationContent()}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ValidationPackage;
