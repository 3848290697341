import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./searchTrip.css";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loading from "../../components/Loading/Loading";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import SeatSelected from "../../assets/images/seat_selected.svg";
import SeatTaken from "../../assets/images/seat_taken.svg";
import SeatAvailable from "../../assets/images/seat_available.svg";
import Volante from "../../assets/images/volante.png";
import ProgressBarLoader from "../../components/ProgressBarLoader/ProgressBarLoader";

export default function SearchTrip() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 0]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [active1, setActive1] = useState("active");
  const [active2, setActive2] = useState("");
  const [active3, setActive3] = useState("");
  const [active4, setActive4] = useState("");
  const [search, setSearch] = useState([]);
  const [schedule_id, setScheduleId] = useState(0);
  const [branch_id, setBranchId] = useState(0);
  const [branchName, setBranchName] = useState("");
  const [origin_id, setOriginId] = useState(0);
  const [destination_id, setDestinationId] = useState(0);
  const [date, setDate] = useState("");
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [dateSelect, setDateSelect] = useState("");
  const [timeSelect, setTimeSelect] = useState("");
  const [priceSelect, setPriceSelect] = useState(0);
  const [priceSelectPet, setPriceSelectPet] = useState(0);
  const [priceSelectExtra, setPriceSelectExtra] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [available, setAvailable] = useState(0);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [occupiedSeats, setOccupiedSeats] = useState([]);
  const [forms, setForms] = useState({});
  const [total, setTotal] = useState(0);
  const [petTotal, setPetTotal] = useState(0);
  const [luggageTotal, setLuggageTotal] = useState(0);
  const [transactionType, setTransactionType] = useState(1);
  const [seatOrder, setSeatOrder] = useState([]);
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;

  useEffect(() => {
    window.scrollTo(0, 0);
    const dataTrip = JSON.parse(localStorage.getItem("dataTrip"));
    setBranchId(dataTrip.branchId);
    setOriginId(dataTrip.originId);
    setDestinationId(dataTrip.destinationId);
    setDate(dataTrip.dateFormat);
    searchTrips(dataTrip);
    setAvailable(dataTrip.available);
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [forms]);

  useEffect(() => {
    if (search.length > 0) {
      const maxPrice = Math.max(...search.map(bus => bus.price || 0));
      setPriceRange([0, maxPrice]);
    }
  }, [search]);

  const status_trip = [
    { label: "Comprar", value: 1 },
    { label: "Reservar", value: 2 },
  ];

  const legendItems = [
    { status: "Disponible", colorClass: "bg-success", icon: SeatAvailable },
    { status: "Ocupado", colorClass: "bg-success", icon: SeatTaken },
    { status: "Seleccionado", colorClass: "bg-success", icon: SeatSelected },
  ];

  const busLayout14 = {
    row1: [0,   0,  0,  14,  1],
    row2: [2,   3,  0,   0,  0],
    row3: [4,   5,  0,   0,  6],
    row4: [7,   8,  0,   0,  9],
    row5: [10, 11,  0,  12, 13],
  };

  const busLayout20 = {
    row1: [0,   0, 0, 20,  1],
    row2: [2,   3, 0,  0,  0],
    row3: [4,   5, 0,  0,  6],
    row4: [7,   8, 0,  0,  9],
    row5: [10, 11, 0,  0, 12],
    row6: [13, 14, 0,  0, 15],
    row7: [16, 17, 0, 18, 19],
  };

  const handleCloseForm = () => setShowForm(false);
  const handleShowForm = () => setShowForm(true);

  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const searchTrips = async (dataTrip) => {
    if (
      dataTrip.branchId == 0 ||
      dataTrip.originId == 0 ||
      dataTrip.destinationId == 0
    ) {
      Swal.fire({
        icon: "error",
        title: '<div className="title-custom-alert">' + TITLEALERT + "</div>",
        showConfirmButton: true,
        html: '<div className="text-custom-alert">Debe seleccionar las opciones para realizar una busqueda</div>',
        showClass: { popup: "animate__animated animate__fadeInDown" },
        hideClass: { popup: "animate__animated animate__fadeOutUp" },
      });
    } else {
      const form = new FormData();
      form.append("branch_id", dataTrip.branchId);
      form.append("origin_id", dataTrip.originId);
      form.append("destination_id", dataTrip.destinationId);
      form.append("date", dataTrip.dateFormat);
      form.append("type", "web");
      setLoading(true);
      await axios
        .post(ENDPOINT + "search-trip", form)
        .then((response) => {
          setLoading(false);
          setSearch(response.data.data);
          setOrigin(response.data.data[0].origin);
          setDestination(response.data.data[0].destination);
          setDateSelect(response.data.data.dateFormat);
        })
        .catch(function (error) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title:
              '<div className="title-custom-alert">' + TITLEALERT + "</div>",
            showConfirmButton: true,
            timer: 3000,
            html:
              '<div className="text-custom-alert">' +
              error.response.data.msg +
              "</div>",
            showClass: { popup: "animate__animated animate__fadeInDown" },
            hideClass: { popup: "animate__animated animate__fadeOutUp" },
          });
        });
    }
  };

  const busTypes = [
    ...new Set(
      search
        .filter((bus) => bus && bus.name_unit)
        .map((bus) => bus.name_unit.split(" - ")[1])
    ),
  ];

  const filterBuses = () => {
    return search.filter(
      (bus) =>
        bus &&
        bus.price &&
        bus.name_unit &&
        parseFloat(bus.price) >= parseFloat(priceRange[0]) &&
        parseFloat(bus.price) <= parseFloat(priceRange[1]) &&
        (selectedTypes.length === 0 || selectedTypes.includes(bus.name_unit.split(" - ")[1]))
    );
  };

  const handlePriceChange = (event) => {
    const value = parseInt(event.target.value);
    setPriceRange([0, value]);
  };

  const handleTypeChange = (type) => {
    setLoading(true);
    setSelectedTypes((prev) => prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const sortBuses = (criterio) => {
    setLoading(true);
    setActive1("");
    setActive2("");
    setActive3("");
    setActive4("");
    const sortedBuses = [...search];
    switch (criterio) {
      case "early":
        setActive1("active");
        sortedBuses.sort((a, b) => a.time.localeCompare(b.time));
        break;
      case "late":
        setActive2("active");
        sortedBuses.sort((a, b) => b.time.localeCompare(a.time));
        break;
      case "cheap":
        setActive3("active");
        sortedBuses.sort((a, b) => a.price - b.price);
        break;
      case "expensive":
        setActive4("active");
        sortedBuses.sort((a, b) => b.price - a.price);
        break;
      default:
        break;
    }
    setTimeout(() => {
      setLoading(false);
      setSearch(sortedBuses);
    }, 300);
  };

  const handleChooseTrip = async (
    schedule_id,
    origin,
    destination,
    date,
    time,
    available,
    branchName,
    price,
    price_pet,
    price_extra
  ) => {
    getAllBookingSeats(schedule_id, date);
    setScheduleId(schedule_id);
    setOrigin(origin);
    setDestination(destination);
    setDateSelect(date);
    setTimeSelect(time);
    setAvailable(available);
    setBranchName(branchName);
    setPriceSelect(price);
    setPriceSelectPet(price_pet);
    setPriceSelectExtra(price_extra);
    handleShowForm();
  };

  const getAllBookingSeats = async (schedule_id, date) => {
    setLoading(true);
    const form = new FormData();
    form.append("schedule_id", schedule_id);
    form.append("date", date);
    await axios
      .post(ENDPOINT + "get-all-booking-seats", form)
      .then((response) => {
        setLoading(false);
        const seats = response.data.data.map((seat) => seat.seat);
        setOccupiedSeats(seats);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user_id = 1;
    const form = new FormData();
    // Crear un array ordenado basado en el orden de selección
    const orderedFormData = seatOrder.map(seatNumber => ({
      ...forms[seatNumber],
      seat: seatNumber
    }));
    
    const dataForm = JSON.stringify(orderedFormData);
    
    form.append("branch_id", branch_id);
    form.append("origin_id", origin_id);
    form.append("destination_id", destination_id);
    form.append("bus_id", schedule_id);
    form.append("user_id", user_id);
    form.append("date", dateSelect);
    form.append("time", timeSelect);
    form.append("data", dataForm);
    form.append("status", 4);
    form.append("type", 2);
    setLoading(true);
    await axios
      .post(ENDPOINT + "save-ticket", form)
      .then((response) => {
        setLoading(false);
        resetAll();
        let dataResume = {
          dataForm: JSON.parse(dataForm),
          origin: origin,
          destination: destination,
          date: dateSelect,
          time: timeSelect,
          total: total,
          purchase_order: response.data.data
        };
        localStorage.setItem("dataResume", JSON.stringify(dataResume));
        navigate("/realizar-pago");
      })
      .catch(function (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: '<div class="title-custom-alert">' + TITLEALERT + "</div>",
          showConfirmButton: true,
          html: '<div class="text-custom-alert">' + error.response.data.msg + "</div>",
          showClass: { popup: "animate__animated animate__fadeInDown" },
          hideClass: { popup: "animate__animated animate__fadeOutUp" },
        });
      });
  };

  const resetAll = () => {
    setSelectedSeats([]);
    setForms({});
    setSeatOrder([]);
    setTotal(0);
    handleCloseForm();
  };

  const handleSeatClick = (seatNumber) => {
    if (occupiedSeats.includes(seatNumber)) return;
    setSelectedSeats((prev) => {
      if (prev.includes(seatNumber)) {
        // Remover asiento del orden
        setSeatOrder(current => current.filter(seat => seat !== seatNumber));
        const newForms = { ...forms };
        delete newForms[seatNumber];
        setForms(newForms);
        return prev.filter((seat) => seat !== seatNumber);
      } else {
        // Agregar asiento al orden
        setSeatOrder(current => [...current, seatNumber]);
        setForms(prev => ({
          ...prev,
          [seatNumber]: {
            name: "",
            last_name: "",
            email: "",
            phone: "",
            price: priceSelect,
            seat: seatNumber,
            pickupLocation: "",
            notes: "",
            cardPayment: 1,
            extraLuggage: 2,
            extraLuggageAmount: priceSelectExtra,
            pet: 2,
            petAmount: priceSelectPet,
            courtesy: 2
          },
        }));
        return [...prev, seatNumber];
      }
    });
  };

  const handleFormChange = (seatNumber, field, value) => {
    setForms((prev) => ({
      ...prev,
      [seatNumber]: {
        ...prev[seatNumber],
        [field]: value,
      },
    }));
    if (field === "pet" || field === "extraLuggage") {
      calculateTotal();
    }
  };

  const calculateTotal = () => {
    let seatTotal = 0;
    let petSum = 0;
    let luggageSum = 0;
    Object.values(forms).forEach((form) => {
      seatTotal += parseFloat(form.price) || 0;
      petSum += form.pet === 1 ? parseFloat(priceSelectPet) : 0;
      luggageSum += form.extraLuggage === 1 ? parseFloat(priceSelectExtra) : 0;
    });
    setTotal(seatTotal + petSum + luggageSum);
    setPetTotal(petSum);
    setLuggageTotal(luggageSum);
  };

  const handleGeneralInfoChange = (value) => {
    setTransactionType(value);
  }

  const renderSeat = (number) => {
    if (!number) return <div className="seat-space" />;
    const isOccupied = occupiedSeats.includes(number);
    const isSelected = selectedSeats.includes(number);
    return (
      <button
        key={number}
        onClick={() => handleSeatClick(number)}
        disabled={isOccupied}
        className={`seat ${isOccupied ? "occupied" : ""} ${
          isSelected ? "selected" : ""
        }`}
        style={{
          backgroundImage: `url(${
            isOccupied ? SeatTaken : isSelected ? SeatSelected : SeatAvailable
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {isOccupied ? (
          <span className="font-semibold"></span>
        ) : isSelected ? (
          <span className="font-semibold text-white">{number}</span>
        ) : (
          <span className="font-semibold">{number}</span>
        )}
      </button>
    );
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formatHour = hour % 12 || 12;
    return `${formatHour}:${minutes} ${ampm}`;
  };

  return (
    
    <div className="travel-booking-system">
      <ProgressBarLoader />
      {loading && <Loading />}
      <Breadcrumbs title="Resultado de busqueda" pagename="Resultado de busqueda" />
      <Modal
        size="xl"
        show={showForm}
        onHide={resetAll}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="bg-header-modal">
          <Modal.Title>
            {branchName} | {origin} - {destination} | Fecha: {dateSelect} Hora:{" "}
            {timeSelect}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="formulario" onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="row">
                {/* Bus Layout */}
                <div className="col-md-4 mb-4">
                  <div className="card sticky-top">
                    <div className="legend-card">
                      <div className="card-header bg-primary text-white text-center">
                        <h5 className="mb-0">Seleccione sus asientos</h5>
                      </div>
                      <div className="legend-container">
                        {legendItems.map((item, index) => (
                          <div key={index} className="legend-item">
                            <div
                              style={{
                                backgroundImage: `url(${item.icon})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                              className={`seat-icon ${item.colorClass}`}
                            ></div>
                            <span className="legend-text">
                              {item.status}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className="card-body">
                        <div className="bus-layout">
                          <div className="driver-seat mb-3">
                            <img src={Volante} alt="volante" />
                          </div>
                          { available === 14 
                            ?  Object.values(busLayout14).map((row, index) => (
                              <div
                                key={index}
                                className="d-flex justify-content-center gap-2 mb-2"
                              >
                                <div className="d-flex gap-2">
                                  {row.slice(0, 2).map((seat, idx) => (
                                    <React.Fragment key={`left-${idx}`}>
                                      {renderSeat(seat)}
                                    </React.Fragment>
                                  ))}
                                </div>
                                <div className="seat-space gap-2" />
                                <div className="d-flex gap-2">
                                  {row.slice(3).map((seat, idx) => (
                                    <React.Fragment key={`right-${idx}`}>
                                      {renderSeat(seat)}
                                    </React.Fragment>
                                  ))}
                                </div>
                              </div>
                            
                            )) : Object.values(busLayout20).map((row, index) => ( 
                              <div
                                  key={index}
                                  className="d-flex justify-content-center gap-2 mb-2"
                                >
                                  <div className="d-flex gap-2">
                                    {row.slice(0, 2).map((seat, idx) => (
                                      <React.Fragment key={`left-${idx}`}>
                                        {renderSeat(seat)}
                                      </React.Fragment>
                                    ))}
                                  </div>
                                  <div className="seat-space gap-2" /> {/* Aisle */}
                                  <div className="d-flex gap-2">
                                    {row.slice(3).map((seat, idx) => (
                                      <React.Fragment key={`right-${idx}`}>
                                        {renderSeat(seat)}
                                      </React.Fragment>
                                    ))}
                                  </div>
                                </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Passenger Forms and General Info */}
                <div className="col-md-8">
                  <div className="passenger-forms-container">
                    {/* Sale Summary */}
                    <div className="card sale-summary mb-4">
                      <div className="card-header bg-primary text-white">
                        <h5 className="mb-0">Resumen de Venta</h5>
                      </div>
                      <div className="card-body">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Concepto</th>
                              <th className="text-end">Monto</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Asientos</td>
                              <td className="text-end">
                                ${total - petTotal - luggageTotal}MXN
                              </td>
                            </tr>
                            <tr>
                              <td>Mascotas</td>
                              <td className="text-end">${petTotal}MXN</td>
                            </tr>
                            <tr>
                              <td>Equipaje extra</td>
                              <td className="text-end">${luggageTotal}MXN</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>Total</td>
                              <td className="text-end">${total}MXN</td>
                            </tr>
                          </tfoot>
                        </table>
                        <div className="d-none transaction-type-wrapper">
                          <label htmlFor="transactionType">Tipo de transacción:</label>
                          <select
                            id="transactionType"
                            className="form-select"
                            value={transactionType}
                            onChange={(e) => handleGeneralInfoChange(e.target.value)}
                          >
                            {status_trip.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="modal-actions">
                          <button
                            type="button"
                            onClick={resetAll}
                            className="cancelBtn"
                          >
                            <i className="bi bi-x-circle-fill fs-5 me-1"></i>
                            <span>Cancelar</span>
                          </button>
                          <button className="successBtn">
                            <i className="bi bi-check-circle-fill fs-5 me-1"></i>
                            <span>
                              {transactionType === 1 ? "Comprar" : "Reservar"}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Passenger Forms */}
                    {seatOrder.map((seatNumber) => (
                      <div key={seatNumber} className="passenger-form-card">
                        <div className="passenger-form-header ">
                          <h5 className="mb-0">
                            <i className="bi bi-person-circle me-2"></i>
                            Asiento {seatNumber}
                          </h5>
                          <button
                            onClick={() => handleSeatClick(seatNumber)}
                            className="btn p-0 text-white"
                          >
                            <i className="bi bi-x-circle-fill fs-5"></i>
                          </button>
                        </div>
                        <div className="passenger-form-body">
                          <div className="form-section">
                            <div className="row g-2">
                              <div className="col-md-6">
                                <label className="form-label">Nombres</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ej: Juan Carlos"
                                  value={forms[seatNumber]?.name || ""}
                                  onChange={(e) => handleFormChange(seatNumber, "name", e.target.value)}
                                  required
                                />
                              </div>
                              <div className="col-md-6">
                                <label className="form-label">Apellidos</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ej: García López"
                                  value={forms[seatNumber]?.last_name || ""}
                                  onChange={(e) => handleFormChange(seatNumber, "last_name", e.target.value)}
                                  required
                                />
                              </div>
                              <div className="col-md-6">
                                <label className="form-label">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="ejemplo@correo.com"
                                  value={forms[seatNumber]?.email || ""}
                                  onChange={(e) => handleFormChange(seatNumber, "email", e.target.value)}
                                  required
                                />
                              </div>
                              <div className="col-md-4">
                                <label className="form-label">Teléfono</label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  placeholder="(999) 123-4567"
                                  value={forms[seatNumber]?.phone || ""}
                                  onChange={(e) => handleFormChange(seatNumber, "phone", e.target.value)}
                                  required
                                />
                              </div>
                              <div className="col-md-2">
                                <label className="form-label">Precio</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={forms[seatNumber]?.price || priceSelect}
                                  placeholder="$ 0.00"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-section">
                            <div className="row g-2">
                              <div className="col-12">
                                <label className="form-label">¿Dónde suben?</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={forms[seatNumber]?.pickupLocation || ""}
                                  onChange={(e) => handleFormChange(seatNumber, "pickupLocation", e.target.value)}
                                  placeholder="Ej: Terminal Central, Puerta 3"
                                />
                              </div>
                              <div className="col-12">
                                <label className="form-label">Anotaciones</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={forms[seatNumber]?.notes || ""}
                                  onChange={(e) => handleFormChange(seatNumber, "notes", e.target.value)}
                                  placeholder="Ej: Persona mayor, necesita asistencia"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-section">
                            <div className="row g-2">
                              <div className="col-md-4 d-none">
                                <label className="form-label d-block">Pago con tarjeta</label>
                                <div className="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name={`cardPayment_${seatNumber}`}
                                    checked={forms[seatNumber]?.cardPayment === 1}
                                    onChange={() => handleFormChange(seatNumber, "cardPayment", 1)}
                                  />
                                  <label className="form-check-label">Sí</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name={`cardPayment_${seatNumber}`}
                                    checked={forms[seatNumber]?.cardPayment === 2}
                                    onChange={() => handleFormChange(seatNumber, "cardPayment", 2)}
                                  />
                                  <label className="form-check-label">No</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <label className="form-label d-block">Exceso de equipaje</label>
                                <div className="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name={`extraLuggage_${seatNumber}`}
                                    checked={forms[seatNumber]?.extraLuggage === 1}
                                    onChange={() => handleFormChange(seatNumber, "extraLuggage", 1)}
                                  />
                                  <label className="form-check-label">Sí</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name={`extraLuggage_${seatNumber}`}
                                    checked={forms[seatNumber]?.extraLuggage === 2}
                                    onChange={() => handleFormChange(seatNumber, "extraLuggage", 2)}
                                  />
                                  <label className="form-check-label">No</label>
                                </div>
                                {forms[seatNumber]?.extraLuggage === 1 && (
                                  <input
                                    readOnly={true}
                                    type="number"
                                    className="form-control form-control-sm mt-2"
                                    value={priceSelectExtra}
                                    onChange={(e) => handleFormChange(seatNumber, "extraLuggageAmount", e.target.value)}
                                    placeholder="Monto"
                                  />
                                )}
                              </div>
                              <div className="col-md-4">
                                <label className="form-label d-block">Mascota</label>
                                <div className="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name={`pet_${seatNumber}`}
                                    checked={forms[seatNumber]?.pet === 1}
                                    onChange={() => handleFormChange(seatNumber, "pet", 1)}
                                  />
                                  <label className="form-check-label">Sí</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name={`pet_${seatNumber}`}
                                    checked={forms[seatNumber]?.pet === 2}
                                    onChange={() => handleFormChange(seatNumber, "pet", 2)}
                                  />
                                  <label className="form-check-label">No</label>
                                </div>
                                {forms[seatNumber]?.pet === 1 && (
                                  <input
                                    readOnly={true}
                                    type="number"
                                    className="form-control form-control-sm mt-2"
                                    value={priceSelectPet}
                                    onChange={(e) => handleFormChange(seatNumber, "petAmount", e.target.value)}
                                    placeholder="Monto"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className="container py-5">
        <div className="main-header">
          <div className="title-section">
            <h1 className="main-title">
              <span className="route-title">
                {origin} <i className="bi bi-arrow-right"></i> {destination}
              </span>
              <span className="date-subtitle">
                {date.split("-")[2]} de {months[date.split("-")[1] - 1]} del{" "}
                {date.split("-")[0]}
              </span>
            </h1>
          </div>
          <div className="header-actions">
            <div className="bus-counter">
              <div className="counter-card">
                <i className="bi bi-bus-front"></i>
                <div className="counter-content">
                  <span className="counter-number">{search.length}</span>
                  <span className="counter-label">Unidades Disponibles</span>
                </div>
              </div>
            </div>
            <button 
              onClick={() => navigate('/')} 
              className="new-search-btn"
            >
              <i className="bi bi-search"></i>
              Nueva búsqueda
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-4 filters-column">
            <div className="card shadow filter-card">
              <div className="card-body">
                <h5 className="filter-title mb-4">Filtros de búsqueda</h5>
                <div className="filter-section mb-4">
                  <label className="filter-label d-flex justify-content-between">
                    <span>Rango de Precios</span>
                    <span className="price-value">
                      ${priceRange[0]} - ${priceRange[1]}
                    </span>
                  </label>
                  <input
                    type="range"
                    min="0"
                    step="1"
                    max={Math.max(...search.map(bus => bus.price || 0))}
                    value={priceRange[1]}
                    onChange={handlePriceChange}
                    className="price-slider form-range"
                  />
                </div>
                <div className="filter-divider"></div>
                <div className="filter-section mb-4">
                  <label className="filter-label mb-3">Ordenar por</label>
                  <div className="sort-buttons">
                    <button
                      className={`btn btn-filter ${active1}`}
                      onClick={() => sortBuses("early")}
                    >
                      <i className="bi bi-sunrise"></i> Más Temprano
                    </button>
                    <button
                      className={`btn btn-filter ${active2}`}
                      onClick={() => sortBuses("late")}
                    >
                      <i className="bi bi-moon-stars"></i> Más Tarde
                    </button>
                    <button
                      className={`btn btn-filter ${active3}`}
                      onClick={() => sortBuses("cheap")}
                    >
                      <i className="bi bi-cash-stack"></i> Menor Precio
                    </button>
                    <button
                      className={`btn btn-filter ${active4}`}
                      onClick={() => sortBuses("expensive")}
                    >
                      <i className="bi bi-cash-coin"></i> Mayor Precio
                    </button>
                  </div>
                </div>
                {busTypes.length > 0 && (
                  <>
                    <div className="filter-divider"></div>
                    <div className="filter-section">
                      <label className="filter-label mb-3">
                        Tipo de Unidad
                      </label>
                      <div className="type-filters">
                        {busTypes.map((type) => (
                          <div key={type} className="form-check type-checkbox">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`type-${type}`}
                              checked={selectedTypes.includes(type)}
                              onChange={() => handleTypeChange(type)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`type-${type}`}
                            >
                              {type}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 buses-column">
            {loading ? (
              <Skeleton count={5} height={200} />
            ) : filterBuses().length === 0 ? (
              <div className="d-flex flex-column align-items-center justify-content-center p-5 text-center" style={{ minHeight: '50vh' }}>
                <div className="empty-results-icon mb-4">
                  <i className="bi bi-emoji-frown" style={{ fontSize: '4rem', color: '#6c757d' }}></i>
                </div>
                <h3 className="mb-3">No se encontraron resultados</h3>
                <p className="text-muted mb-4">
                  Lo sentimos, no hay viajes disponibles para los criterios seleccionados.<br />
                  Por favor, intenta con una nueva búsqueda.
                </p>
                <button
                  className="primaryBtn d-flex align-items-center gap-2"
                  onClick={() => navigate(-1)}
                >
                  <i className="bi bi-arrow-left-circle"></i>
                  Realizar nueva búsqueda
                </button>
              </div>
            ) : (
              filterBuses()
                .filter((bus) => bus && bus.name_unit)
                .map((bus) => (
                  <div key={bus.id} className="card shadow mb-4">
                    <div className="card-header">
                      <div className="bus-info">
                        <div className="bus-icon-wrapper">
                          <i
                            className="bi bi-bus-front font-bold"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <h3>{bus.name_unit}</h3>
                      </div>
                      <div className="route-info">
                        <i className="bi bi-pin-map-fill"></i>
                        <span>{bus.origin}</span>
                        <i className="bi bi-arrow-right arrow-icon"></i>
                        <span>{bus.destination}</span>
                        <i className="bi bi-geo-alt-fill"></i>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="journey-info">
                        <div className="location start">
                          <i className="bi bi-pin-map-fill"></i>
                          <span>{bus.origin}</span>
                        </div>
                        <div className="journey-line">
                          <span className="duration-text">{bus.duration}</span>
                        </div>
                        <div className="location end">
                          <span>{bus.destination}</span>
                          <i className="bi bi-geo-alt-fill"></i>
                        </div>
                      </div>
                      <div className="booking-info">
                        <div className="price">
                          ${bus.price}
                          <span>MXN</span>
                        </div>
                        <div className="booking-info-center">
                          <div className="time-wrapper">
                            <i className="bi bi-stopwatch-fill"></i>
                            <span>{formatTime(bus.time)}</span>
                          </div>
                          <div className="availability">
                            <i className="bi bi-person-check me-2"></i>
                            {bus.seats} Disponibles
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            handleChooseTrip(
                              bus.schedule_id,
                              bus.origin,
                              bus.destination,
                              bus.date,
                              bus.time,
                              bus.available,
                              bus.base,
                              bus.price,
                              bus.price_pet,
                              bus.price_extra
                            )
                          }
                          className="btn btn-outline-primary"
                        >
                          <span>Seleccionar</span>
                          <i className="bi bi-arrow-right-circle-fill"></i>
                        </button>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="amenities">
                        <i className="bi bi-wifi" title="WiFi Gratis"></i>
                        <i className="bi bi-plug" title="Tomacorriente"></i>
                        <i className="bi bi-usb-plug-fill" title="Tomas USB"></i>
                        <i className="bi bi-thermometer-snow" title="Aire Acondicionado"></i>
                        <i className="bi bi-shield-check" title="Viaje Seguro"></i>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
