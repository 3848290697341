import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ProgressBarLoader from "../../components/ProgressBarLoader/ProgressBarLoader";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./validationInvoice.css";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import Swal from "sweetalert2";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ValidationInvoice = () => {
  const navigate = useNavigate();
  const {invoice} = useParams();
  const [validationStatus, setValidationStatus] = useState("failed"); // 'valid', 'completed', 'expired', 'failed'
  const [ticketInfo, setTicketInfo] = useState([]);
  const [link_trip, setLinkTrip] = useState("");
  const [loading, setLoading] = useState(true);
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;

  const validateInvoice = async () => {
    const form = new FormData();
    form.append("invoice", invoice);
    setLoading(true);
    await axios
      .post(ENDPOINT + "validation-ticket-qrcode", form)
      .then((response) => {
        setLoading(false);
        setTicketInfo(response.data.data);
        setLinkTrip(response.data.data.link_trip);
        const code_qr = response.data.data.code_qr;
        if(code_qr == 2){
          setValidationStatus("valid");
        } else if(code_qr == 3){
          setValidationStatus("completed");
        } else if(code_qr == 4){
          setValidationStatus("expired");
        } else {
          setValidationStatus("failed");
        }
      }).catch(function (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: '<div className="title-custom-alert">' + TITLEALERT + "</div>",
          showConfirmButton: true,
          timer: 3000,
          html: '<div className="text-custom-alert">' + error.response.data.msg + "</div>",
          showClass: { popup: "animate__animated animate__fadeInDown" },
          hideClass: { popup: "animate__animated animate__fadeOutUp" },
        });
      });
  };

  useEffect(() => {
    validateInvoice();
  }, [invoice]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };

  const formatTime = (timeString) => {
    if (!timeString) return "";
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleTimeString('es-ES', options);
  };

  const seeLocation = () => {
    if (link_trip === 'NA') {
      Swal.fire({
        icon: "error",
        title: '<div className="title-custom-alert">' + TITLEALERT + "</div>",
        showConfirmButton: true,
        html: '<div className="text-custom-alert">La ubicación de la unidad no ha sido asignada.</div>',
        showClass: { popup: "animate__animated animate__fadeInDown" },
        hideClass: { popup: "animate__animated animate__fadeOutUp" },
      });
    } else {
      window.open(link_trip, '_blank');
    }
  };

  const passengerDetails = (
    <div className="passenger-details enhanced">
      <div className="detail-item">
        <strong>No. Folio:</strong>
        <span>{invoice}</span>
      </div>
      <div className="detail-item">
        <strong>Nombre del pasajero:</strong>
        <span>{ticketInfo.name + ' ' + ticketInfo.last_name}</span>
      </div>
      <div className="detail-item">
        <strong>Fecha de viaje:</strong>
        <span>{formatDate(ticketInfo.date_ticket)}</span>
      </div>
      <div className="detail-item">
        <strong>Horario:</strong>
        <span>{formatTime(ticketInfo.time_ticket)}</span>
      </div>
      <div className="detail-item">
        <strong>Puesto:</strong>
        <span>{ticketInfo.seat}</span>
      </div>
    </div>
  );

  const renderValidationContent = () => {
    if (loading) {
      return (
        <Card className="validation-card loading">
          <Skeleton height={40} width={200} />
          <Skeleton height={20} count={3} />
          <Skeleton height={30} width={150} />
        </Card>
      );
    }

    switch (validationStatus) {
      case "valid":
        return (
          <Card className="validation-card valid">
            <div className="validation-icon success">
              <i className="bi bi-check-circle"></i>
            </div>
            <div className="validation-content">
              <h1 className="validation-title">¡Validación Exitosa!</h1>
              <div className="validation-message">
                <p className="primary-message">
                  El boleto ha sido verificado correctamente.
                </p>
                <div className="message-details">
                  <p>✓ Boleto autenticado en el sistema</p>
                  <p>✓ Documento válido para viajar</p>
                  <p>✓ Verificación completada con éxito</p>
                </div>
                {passengerDetails}
              </div>
              <div className="action-buttons">
                <button
                  onClick={() => navigate("/")}
                  className="validation-btn valid"
                >
                  <i className="bi bi-house-door me-2"></i>
                  Volver al inicio
                </button>
                <button
                  onClick={() => seeLocation()}
                  className="validation-btn location"
                >
                  <i className="bi bi-geo-alt me-2"></i>
                  Ver Ubicación de tu Unidad
                </button>
              </div>
            </div>
          </Card>
        );
      case "completed":
        return (
          <Card className="validation-card completed">
            <div className="validation-icon completed">
              <i className="bi bi-check-circle"></i>
            </div>
            <div className="validation-content">
              <h1 className="validation-title completed">Boleto Completado</h1>
              <div className="validation-message">
                <p className="primary-message completed">
                  El boleto ya ha sido utilizado.
                </p>
                <div className="message-details">
                  <p>✓ El boleto ya ha sido utilizado y el viaje ha sido completado.</p>
                  <p>✓ No es posible reutilizar este boleto.</p>
                  <p>✓ Verificación completada con éxito.</p>
                </div>
                {passengerDetails}
              </div>
              <div className="action-buttons">
                <button
                  onClick={() => navigate("/")}
                  className="validation-btn completed"
                >
                  <i className="bi bi-house-door me-2"></i>
                  Volver al inicio
                </button>
                <button
                  onClick={() => seeLocation()}
                  className="validation-btn location"
                >
                  <i className="bi bi-geo-alt me-2"></i>
                  Ver Ubicación de tu Unidad
                </button>
              </div>
            </div>
          </Card>
        );
      case "expired":
        return (
          <Card className="validation-card expired">
            <div className="validation-icon expired">
              <i className="bi bi-exclamation-circle"></i>
            </div>
            <div className="validation-content">
              <h1 className="validation-title expired">Boleto Expirado</h1>
              <div className="validation-message">
                <p className="primary-message expired">
                  El boleto ha expirado.
                </p>
                <div className="message-details">
                  <p>✗ El boleto ha expirado y no es válido para viajar.</p>
                  <p>✗ El viaje asociado ya fue realizado.</p>
                  <p>✗ No es posible reutilizar este boleto.</p>
                </div>
                {passengerDetails}
              </div>
              <div className="action-buttons">
                <button
                  onClick={() => navigate("/")}
                  className="validation-btn expired"
                >
                  <i className="bi bi-house-door me-2"></i>
                  Volver al inicio
                </button>
              </div>
            </div>
          </Card>
        );
      case "failed":
      default:
        return (
          <Card className="validation-card failed">
            <div className="validation-icon failed">
              <i className="bi bi-x-circle"></i>
            </div>
            <div className="validation-content">
              <h1 className="validation-title failed">Validación Fallida</h1>
              <div className="validation-message">
                <p className="primary-message failed">
                  No se pudo verificar el boleto.
                </p>
                <div className="message-details">
                  <p>✗ El código de validación es inválido.</p>
                  <p>✗ No se encontró información del boleto.</p>
                  <p>✗ Por favor, intente nuevamente.</p>
                </div>
              </div>
              <div className="action-buttons">
                <button
                  onClick={() => navigate("/")}
                  className="validation-btn failed"
                >
                  <i className="bi bi-house-door me-2"></i>
                  Volver al inicio
                </button>
              </div>
            </div>
          </Card>
        );
    }
  };

  return (
    <>
      <ProgressBarLoader />
      {loading && <Loading />}
      <Breadcrumbs
        title="Validación de Boleta"
        pagename="Validación de Boleta"
      />
      <section className="validation-section">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6">
              {renderValidationContent()}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ValidationInvoice;
