import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./HeroBanner.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const HeroBanner = () => {
  const [currentBanner, setCurrentBanner] = useState(0);
  const [infoBanners, setInfoBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const URLZFILES = process.env.REACT_APP_API_ZFILES;
  const URLPUBLIC = process.env.REACT_APP_PUBLIC;

  useEffect(() => {
    fetch(URLPUBLIC + 'data/pagehome/banners.json')
      .then(response => response.json())
      .then(data => {
        setInfoBanners(data);
        setLoading(false);
      });
    const interval = setInterval(() => {
      setCurrentBanner((prevBanner) => (prevBanner + 1) % infoBanners.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [infoBanners.length]);

  const handlePrev = () => {
    setCurrentBanner(
      (prevBanner) => (prevBanner - 1 + infoBanners.length) % infoBanners.length
    );
  };

  const handleNext = () => {
    setCurrentBanner((prevBanner) => (prevBanner + 1) % infoBanners.length);
  };

  return (
    <div className="hero-banner">
      <div className="overlay"></div>
      {loading ? (
        <Skeleton height="80vh" />
      ) : (
        infoBanners.map((banner, index) => (
          <div
            key={index}
            className={`background-image ${index === currentBanner ? "active" : ""}`}
            style={{ backgroundImage: `url(${URLZFILES + banner.banner_image})` }}
          ></div>
        ))
      )}
      <div className="hero-container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-12 text-center">
            {loading ? (
              <>
                <Skeleton width={300} height={50} />
                <Skeleton width={400} height={30} />
              </>
            ) : (
              <>
                <h1 className="display-3 text-white mb-4 hero-title">
                  {infoBanners[currentBanner].banner_title}
                </h1>
                <p className="lead text-white mb-5 hero-subtitle">
                  <span
                    className="hero-subtitle"
                    dangerouslySetInnerHTML={{ __html: infoBanners[currentBanner].banner_sub_title }}
                  />
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {!loading && (
        <>
          <button className="nav-button prev" onClick={handlePrev}>
            &#10094;
          </button>
          <button className="nav-button next" onClick={handleNext}>
            &#10095;
          </button>
        </>
      )}
    </div>
  );
};

export default HeroBanner;
