import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import Skeleton from 'react-loading-skeleton';
import 'react-image-lightbox/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import Gallery1  from '../../assets/images/gallery/foto1.jpg';
import Gallery2  from '../../assets/images/gallery/foto2.jpg';
import Gallery3  from '../../assets/images/gallery/foto3.jpg';
import Gallery4  from '../../assets/images/gallery/foto4.jpg';
import Gallery5  from '../../assets/images/gallery/foto5.jpg';
import Gallery6  from '../../assets/images/gallery/foto6.jpg';
import Gallery7  from '../../assets/images/gallery/foto7.jpg';
import Gallery8  from '../../assets/images/gallery/foto8.jpg';
import Gallery9  from '../../assets/images/gallery/foto9.jpg';
import Gallery10 from '../../assets/images/gallery/foto10.jpg';
import Gallery11 from '../../assets/images/gallery/foto11.jpg';
import Gallery12 from '../../assets/images/gallery/foto12.jpg';
import Gallery13 from '../../assets/images/gallery/foto13.jpg';
import Gallery14 from '../../assets/images/gallery/foto14.jpg';
import Gallery15 from '../../assets/images/gallery/foto15.jpg';

const allPhotos = [
  {
    src: Gallery1,
    thumbnail: Gallery1,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery2,
    thumbnail: Gallery2,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery3,
    thumbnail: Gallery3,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery4,
    thumbnail: Gallery4,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery5,
    thumbnail: Gallery5,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery6,
    thumbnail: Gallery6,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery7,
    thumbnail: Gallery7,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery8,
    thumbnail: Gallery8,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery9,
    thumbnail: Gallery9,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery10,
    thumbnail: Gallery10,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery11,
    thumbnail: Gallery11,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery12,
    thumbnail: Gallery12,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery13,
    thumbnail: Gallery13,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery14,
    thumbnail: Gallery14,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  },
  {
    src: Gallery15,
    thumbnail: Gallery15,
    title: 'Fotos de nuestra unidad',
    subtitle: 'Fotos de nuestra unidad'
  }
];

const Gallery = () => {
  const [displayedPhotos, setDisplayedPhotos] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadMorePhotos();
  }, []);

  const loadMorePhotos = () => {
    setLoading(true);
    setTimeout(() => {
      const currentLength = displayedPhotos.length;
      const newPhotos = allPhotos.slice(currentLength, currentLength + 6);
      setDisplayedPhotos([...displayedPhotos, ...newPhotos]);
      setLoading(false);
    }, 500);
  };

  return (
    <Container className="tetx-center">
      <Row className="g-4">
        {loading ? (
          Array.from({ length: 6 }).map((_, index) => (
            <Col xs={12} sm={6} md={4} key={index}>
              <Skeleton height={230} />
            </Col>
          ))
        ) : (
          displayedPhotos.map((photo, index) => (
            <Col xs={12} sm={6} md={4} key={index}>
              <div 
                className="photo-card"
                onClick={() => {
                  setPhotoIndex(index);
                  setIsOpen(true);
                }}
              >
                <img
                  src={photo.thumbnail}
                  alt={photo.title}
                  className="img-fluid rounded"
                  style={{ width: '100%', height: '230px', objectFit: 'cover' }}
                />
                <div className="photo-overlay">
                  <h3>{photo.title}</h3>
                </div>
              </div>
            </Col>
          ))
        )}
      </Row>
      {displayedPhotos.length < allPhotos.length && (
        <div className="d-flex justify-content-center align-items-center text-center mt-4">
          <Button 
            variant="outline-primary" 
            size="md" 
            onClick={loadMorePhotos}
            disabled={loading}
            className="modern-button"
          >
            {loading ? 'Cargando...' : 'Ver más'}
          </Button>
        </div>
      )}

      {isOpen && (
        <Lightbox
          mainSrc={displayedPhotos[photoIndex].src}
          nextSrc={displayedPhotos[(photoIndex + 1) % displayedPhotos.length].src}
          prevSrc={displayedPhotos[(photoIndex + displayedPhotos.length - 1) % displayedPhotos.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + displayedPhotos.length - 1) % displayedPhotos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % displayedPhotos.length)
          }
          imageTitle={
            <h2 style={{ textAlign: 'center', color: 'white', fontWeight: 300 }}>
              {displayedPhotos[photoIndex].title}
            </h2>
          }
          imageCaption={
            <p style={{ textAlign: 'center', color: 'white', fontWeight: 300 }}>
              {displayedPhotos[photoIndex].subtitle}
            </p>
          }
        />
      )}

      <style jsx>{`
        .photo-card {
          position: relative;
          overflow: hidden;
          cursor: pointer;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          border-radius: 8px;
        }
        .photo-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 10px 20px rgba(0,0,0,0.2);
        }
        .photo-overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(to top, rgba(0,0,0,0.2) 0%, rgba(0,0,0,1) 100%);
          color: white;
          padding: 20px;
          opacity: 0;
          transition: opacity 0.3s ease;
        }
        .photo-card:hover .photo-overlay {
          opacity: 1;
        }
        .photo-overlay h3 {
          margin: 0;
          font-size: 0.9rem;
          font-weight: 300;
          letter-spacing: 0.5px;
        }
        .modern-button {
          transition: all 0.3s ease;
          border-radius: 30px;
          padding: 10px 30px;
          font-weight: 300;
          letter-spacing: 1px;
          text-transform: uppercase;
          box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        }
        .modern-button:hover {
          transform: translateY(-2px);
          box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        }
        .modern-button:active {
          transform: translateY(1px);
        }
      `}</style>
    </Container>
  );
};

export default Gallery;