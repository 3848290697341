import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import "./termsAndConditions.css";
import ProgressBarLoader from "../../components/ProgressBarLoader/ProgressBarLoader";

const TermsAndConditions = () => {
  return (
    <div>
      <ProgressBarLoader />
      <Breadcrumbs  title="Términos y Condiciones" pagename="Términos y Condiciones" />
      <div className="container py-5">
        <h1 className="text-center mb-5 page-title">Términos y Condiciones</h1>
        <div className="terms-content">
          <h3 className="section-title">1. Aceptación de los Términos</h3>
          <p>
            Al utilizar los servicios de venta de boletos de bus de Grupo Viala,
            usted acepta cumplir y estar sujeto a estos términos y condiciones.
            Si no está de acuerdo con alguna parte de estos términos, no podrá
            utilizar nuestros servicios.
          </p>

          <h3 className="section-title">2. Uso del Servicio</h3>
          <p>
            Nuestro servicio permite la compra de boletos de bus para diferentes
            rutas y destinos. Usted se compromete a proporcionar información
            precisa y completa al realizar una compra.
          </p>

          <h3 className="section-title">3. Política de Precios y Pagos</h3>
          <p>
            Los precios de las boletos están sujetos a cambios sin previo aviso.
            Todos los pagos se procesarán de manera segura a través de nuestras
            plataformas de pago autorizadas.
          </p>

          <h3 className="section-title">4. Cancelaciones y Reembolsos</h3>
          <p>
            Las cancelaciones y solicitudes de reembolso están sujetas a la
            política de la empresa de transporte específica. Grupo Viala actuará
            como intermediario en estos procesos.
          </p>

          <h3 className="section-title">5. Responsabilidad</h3>
          <p>
            Grupo Viala actúa como intermediario entre los pasajeros y las
            empresas de transporte. No nos hacemos responsables por cambios en
            los horarios, cancelaciones o cualquier inconveniente causado por
            las empresas de transporte.
          </p>

          <h3 className="section-title">6. Privacidad y Protección de Datos</h3>
          <p>
            Su privacidad es importante para nosotros. Recopilamos y utilizamos
            su información personal de acuerdo con nuestra Política de
            Privacidad, que forma parte integral de estos términos y
            condiciones.
          </p>

          <h3 className="section-title">7. Modificaciones a los Términos</h3>
          <p>
            Nos reservamos el derecho de modificar estos términos y condiciones
            en cualquier momento. Las modificaciones entrarán en vigor
            inmediatamente después de su publicación en nuestro sitio web.
          </p>

          <h3 className="section-title">8. Contacto</h3>
          <p>
            Si tiene alguna pregunta sobre estos términos y condiciones, por
            favor contáctenos a través de nuestro sitio web o en nuestras
            oficinas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
